.header
{
  background:#5FAFFF;
  display: flex;
  align-items: center;
  width: 100%;
  height: 7.5vh;
  font-size: 12px;
  justify-content: space-between;
  position: fixed;
  top:0;
  z-index: 100;
}


.header-element
{
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0.5em;

}



.header-element__logo
{
  width:5em; 
  height:2.5em;
}



.header-element__nombre
{
  background:none;
  color:white;
  font-size: 2em;  
  margin:auto;
}



.header-element__star
{
    margin:auto;
    margin-right: 0.2em;
    font-weight: 400;
    color: white;
    font-size: 1.3em;  
}


.header-element__item
{
    width:1.7em; 
    height:1.7em;
    margin-right: 1em;
}


.header-element__imgUser
{
    margin: 0 auto;
    height:75%;
    border-radius: 20em;
}


.link-perfil
{
  height:100%;
  width:auto;
  display:flex;
  align-items:center;
}


/* Mobile devices
@media (min-width: 320px) and (max-width: 480px) 
*/

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) 
{
  
}
/* 
Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) 
{ 

}

/* Desktops, large screens */
@media (min-width: 1025px)  and (max-width: 1200px)
{

}
/* Extra large screens, TV */
@media screen and (min-width:1201px)
{
  .header
  {
    font-size: 13px;
  } 
}
