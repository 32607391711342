.content-window
{
    margin-top: 7.5vh;
    height: 80.5vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}


.content-window--up
{
  justify-content: flex-start;
  position: relative;
  z-index: 100;
  overflow: scroll;
}





.content-window--emergente
{
  margin-top: 10vh;
  height: 55em;
  width: 45em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  background: white;
  top:-3vh;
  right: 1.5vh;
  font-size: 5.5px;
  padding: 3em;
  border-radius: 1.5em;
  box-shadow:1px 2px 7px 3px #cfcfcf;
-moz-box-shadow:1px 2px 7px 3px #cfcfcf;
-webkit-box-shadow:1px 2px 7px 3px #cfcfcf;
  /* overflow: scroll; */
}



.img-User
{
  margin: 3em;
  width: 12em;
  height: 12em;
  border-radius: 5em;
}


.info-user
{
  display: flex;
  align-items: center;
}


.linea-info-user
{
  height: 0.4em;
  width: 8em;
  border-radius: 1em;
  background: #C371F9;
}

.info-user__texto
{
  color:#C371F9;
  margin: auto 0.6em;
  font-size: 1.7em;
  font-weight: 700;
}

.info-item
{
  display: flex;
  align-items: center;
  
  width: 85%;
  margin: 1.5em 0;
  padding: 0.5em 0;
}

.info-item__icon
{
  width: 2.5em;
  height: 2.5em;
  margin-left: 1em;
}

.info-item__texto
{
  color: #B4B4B4;
  font-size: 1.5em;
  margin: auto 1.3em;
}





@media screen and (min-width: 768px) 
{ 
  .content-window--emergente
  {
      font-size: 10px;

  }

  .info-item
  {
    width: 60%;
  }
  
.info-item__texto
{
  color: #B4B4B4;
  font-size: 2em;
}

.info-item__icon
{
  width: 3em;
  height: 3em;
}

.info-user__texto
{
  font-size: 2.5em;

}


}

@media  (min-width: 769px) and (max-width: 1024px) 
{
  .content-window--emergente
  {
      font-size: 10px;
      height: 70em;
      width: 45em;
  
  }
}

@media  screen and (min-width: 1025px) 
{
  .content-window--emergente
    {
      font-size: 7px;
      height: 65em;
      width: 50em;

    }


}

